/**
 * To see how these statuses are inter-related:
 * https://www.notion.so/zeal-api/WIP-i9-and-e-verify-7e4b1de36061477d8ada3dbcac88e158?pvs=4#3dcf21bf66e24f0fa2b646c16e658284
 */

/**
 * Indicates the status of the e-verify case set by USCIS.
 */
export enum EEVerifyUSCISCaseStatus {
  DRAFT = "DRAFT",
  UNCONFIRMED_DATA = "UNCONFIRMED_DATA",
  PHOTO_MATCH = "PHOTO_MATCH",
  SCAN_AND_UPLOAD = "SCAN_AND_UPLOAD",
  MANUAL_REVIEW = "MANUAL_REVIEW",
  PENDING_REFERRAL = "PENDING_REFERRAL",
  REFERRED = "REFERRED",
  QUEUED = "QUEUED",
  FINAL_NONCONFIRMATION = "FINAL_NONCONFIRMATION",
  CLOSE_CASE_AND_RESUBMIT = "CLOSE_CASE_AND_RESUBMIT",
  CLOSED = "CLOSED",
}

/**
 * Indicates the status of the e-verify case set by Zeal, visible internally.
 */
export enum EEVerifyInternalCaseStatus {
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CLOSED = "CLOSED",
}

/**
 * Indicates the status of the e-verify case set by Zeal, visible to end-users.
 */
export enum EEVerifyPublicCaseStatus {
  IN_PROGRESS = "IN_PROGRESS",
  PENDING_REFERRAL = "PENDING_REFERRAL", // To download Further Action Notice and send to the worker
  REFERRED = "REFERRED", // To download Referral Date Confirmation and send to the worker
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  FAILURE_NO_SHOW = "FAILURE_NO_SHOW", // Worker didn't contact the agencies in time after referring their case following a temporary non-confirmation
  FAILURE_NO_ACTION = "FAILURE_NO_ACTION", // Worker chose to not take any action after receiving a temporary non-confirmation
}

export const eVerifyPublicStatusesThatRequireActionByAdmin = [
  EEVerifyPublicCaseStatus.FAILURE,
  EEVerifyPublicCaseStatus.FAILURE_NO_ACTION,
  EEVerifyPublicCaseStatus.FAILURE_NO_SHOW,
  EEVerifyPublicCaseStatus.PENDING_REFERRAL,
  EEVerifyPublicCaseStatus.REFERRED,
];

export const getWhetherWorkerRequiresEVerifyActionByAdmin = (
  status?: EEVerifyPublicCaseStatus,
  status_display?: string
) => {
  if (!status) {
    return false;
  }

  if (
    [
      EEVerifyPublicCaseStatus.FAILURE,
      EEVerifyPublicCaseStatus.FAILURE_NO_ACTION,
      EEVerifyPublicCaseStatus.FAILURE_NO_SHOW,
    ].includes(status)
  ) {
    return status_display !== "Closed";
  }

  return eVerifyPublicStatusesThatRequireActionByAdmin.includes(status);
};

export enum EEVerifyCaseEligibilityStatement {
  EMPLOYMENT_AUTHORIZED = "EMPLOYMENT_AUTHORIZED",
  NOT_AUTHORIZED_EMPLOYMENT = "NOT_AUTHORIZED_EMPLOYMENT",
  RESUBMIT_CASE = "RESUBMIT_CASE",
  NO_SHOW = "NO_SHOW",
  NO_ACTION_FNC = "NO_ACTION_FNC",
}

export const getPublicCaseStatusFromUSCISInfo = ({
  case_status,
  case_eligibility_statement,
}: {
  case_status: EEVerifyUSCISCaseStatus;
  case_eligibility_statement?: EEVerifyCaseEligibilityStatement;
}) => {
  switch (case_status) {
    case EEVerifyUSCISCaseStatus.DRAFT:
    case EEVerifyUSCISCaseStatus.UNCONFIRMED_DATA:
    case EEVerifyUSCISCaseStatus.PHOTO_MATCH:
    case EEVerifyUSCISCaseStatus.SCAN_AND_UPLOAD:
    case EEVerifyUSCISCaseStatus.MANUAL_REVIEW:
    case EEVerifyUSCISCaseStatus.QUEUED:
    case EEVerifyUSCISCaseStatus.CLOSE_CASE_AND_RESUBMIT: {
      return EEVerifyPublicCaseStatus.IN_PROGRESS;
    }

    case EEVerifyUSCISCaseStatus.PENDING_REFERRAL:
      return EEVerifyPublicCaseStatus.PENDING_REFERRAL;

    case EEVerifyUSCISCaseStatus.REFERRED:
      return EEVerifyPublicCaseStatus.REFERRED;

    case EEVerifyUSCISCaseStatus.FINAL_NONCONFIRMATION:
      switch (case_eligibility_statement) {
        case EEVerifyCaseEligibilityStatement.NO_SHOW:
          return EEVerifyPublicCaseStatus.FAILURE_NO_SHOW;
        case EEVerifyCaseEligibilityStatement.NO_ACTION_FNC:
          return EEVerifyPublicCaseStatus.FAILURE_NO_ACTION;
        default:
          return EEVerifyPublicCaseStatus.FAILURE;
      }

    case EEVerifyUSCISCaseStatus.CLOSED: {
      switch (case_eligibility_statement) {
        case EEVerifyCaseEligibilityStatement.EMPLOYMENT_AUTHORIZED:
          return EEVerifyPublicCaseStatus.SUCCESS;
        case EEVerifyCaseEligibilityStatement.NOT_AUTHORIZED_EMPLOYMENT:
          return EEVerifyPublicCaseStatus.FAILURE;
        case EEVerifyCaseEligibilityStatement.RESUBMIT_CASE:
          return EEVerifyPublicCaseStatus.IN_PROGRESS;
        case EEVerifyCaseEligibilityStatement.NO_SHOW:
          return EEVerifyPublicCaseStatus.FAILURE_NO_SHOW;
        case EEVerifyCaseEligibilityStatement.NO_ACTION_FNC:
          return EEVerifyPublicCaseStatus.FAILURE_NO_ACTION;
        default:
          // Unlikely, but just in case
          return EEVerifyPublicCaseStatus.IN_PROGRESS;
      }
    }
    default:
      return EEVerifyPublicCaseStatus.IN_PROGRESS;
  }
};

/**
 * Indicates the actionable status of the e-verify case set by Zeal, visible internally.
 */
export enum EEVerifyInternalActionableStatus {
  PHOTO_MATCH = "PHOTO_MATCH",
  SCAN_AND_UPLOAD = "SCAN_AND_UPLOAD",
  PENDING_REFERRAL = "PENDING_REFERRAL",
  CLOSE_CASE_AND_RESUBMIT = "CLOSE_CASE_AND_RESUBMIT",
}

/**
 * Indicates the sub-status of the e-verify case set by the SSA or DHA, visible internally.
 */
export enum EEVerifyAgencyCaseSubStatus {
  PENDING_REFERRAL = "PENDING_REFERRAL",
  REFERRED = "REFERRED",
  CONTINUANCE = "CONTINUANCE",
  RESOLVED = "RESOLVED",
  NOT_REFERRED = "NOT_REFERRED",
}

/**
 * The reasons a case is closed
 */
export enum EEVerifyCaseClosureReasons {
  EMPLOYEE_QUIT = "EMPLOYEE_QUIT",
  PENDING_EMPLOYEE_ACTION_AUTO_CLOSE = "PENDING_EMPLOYEE_ACTION_AUTO_CLOSE",
  EMPLOYER_IS_NO_LONGER_CLIENT = "EMPLOYER_IS_NO_LONGER_CLIENT",
  EMPLOYER_INSTRUCTED_TO_CLOSE = "EMPLOYER_INSTRUCTED_TO_CLOSE",
  NO_LONGER_EMPLOYED = "NO_LONGER_EMPLOYED",
  INVALID_QUERY = "INVALID_QUERY",
  EMPLOYMENT_NOT_AUTHORIZED = "EMPLOYMENT_NOT_AUTHORIZED",
  SELF_TERMINATED = "SELF_TERMINATED",
  EMPLOYEE_NOT_TERMINATED = "EMPLOYEE_NOT_TERMINATED",
  FORCED_CLOSURE = "FORCED_CLOSURE",
  REVIEWED = "REVIEWED",
  OPERATOR_ERROR = "OPERATOR_ERROR",
  EMPLOYMENT_AUTHORIZED = "EMPLOYMENT_AUTHORIZED",
  DUPLICATE_CASE_ENTERED_BY_EMPLOYER = "DUPLICATE_CASE_ENTERED_BY_EMPLOYER",
  EXPIRED_DOCUMENT = "EXPIRED_DOCUMENT",
  EMPLOYER_INSTRUCTED_TO_CREATE_NEW_CASE = "EMPLOYER_INSTRUCTED_TO_CREATE_NEW_CASE",
  TECHNICAL_ISSUES_WITH_EVERIFY = "TECHNICAL_ISSUES_WITH_EVERIFY",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_EA = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_EA",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC",
  EMPLOYEE_TERMINATED_FOR_FNC_RESULT = "EMPLOYEE_TERMINATED_FOR_FNC_RESULT",
  EMPLOYEE_TERMINATED_FOR_OTHER_REASONS = "EMPLOYEE_TERMINATED_FOR_OTHER_REASONS",
  EMPLOYEE_TERMINATED_FOR_NO_SHOW = "EMPLOYEE_TERMINATED_FOR_NO_SHOW",
  INCORRECT_DATA = "INCORRECT_DATA",
  EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC = "EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC",
  DUPLICATE_CASE_DATA_EXISTS = "DUPLICATE_CASE_DATA_EXISTS",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA",
  OTHER = "OTHER",
  ADMINISTRATIVELY_CLOSED = "ADMINISTRATIVELY_CLOSED",
  INCOMPLETE_ADMIN_CLOSED = "INCOMPLETE_ADMIN_CLOSED",
  OTHER_FREE_TEXT = "OTHER_FREE_TEXT",
  EA_AUTO_CLOSE = "EA_AUTO_CLOSE",
}

/**
 * All accepted reasons for closing a case when the worker failed e-verify
 */
export enum EEVerifyCaseClosureReasonsAfterUnsuccessfulResult {
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW",
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC",
  EMPLOYEE_TERMINATED_FOR_FNC_RESULT = "EMPLOYEE_TERMINATED_FOR_FNC_RESULT",
  EMPLOYEE_TERMINATED_FOR_NO_SHOW = "EMPLOYEE_TERMINATED_FOR_NO_SHOW",
  EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC = "EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC",
}

/**
 * All accepted reasons for closing a case when the worker chose to take no action
 * after receiving a TNC (Tentative Non-Confirmation)
 */
export enum EEVerifyCaseClosureReasonsAfterUncontestedTNC {
  EMPLOYEE_CONTINUES_TO_WORK = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_UNCONTESTED_TNC",
  EMPLOYEE_TERMINATED = "EMPLOYEE_TERMINATED_FOR_UNCONTESTED_TNC",
  INCORRECT_DATA = "INCORRECT_DATA",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA",
  OTHER = "OTHER",
}

/**
 * All accepted reasons for closing a case when the worker didn't contact the agencies in time
 * after referring their case following a temporary non-confirmation
 */
export enum EEVerifyCaseClosureReasonsAfterNoShow {
  EMPLOYEE_CONTINUES_TO_WORK = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_NO_SHOW",
  EMPLOYEE_TERMINATED = "EMPLOYEE_TERMINATED_FOR_NO_SHOW",
  INCORRECT_DATA = "INCORRECT_DATA",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA",
  OTHER = "OTHER",
}

/**
 * Used by the company admin UI. Not all statuses below translate directly to a USCIS status.
 * Check the getUSCISCaseClosureReasonFromPublicReason function for the mapping.
 */
export enum EEVerifyCaseClosureReasonsAfterFailure {
  EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC = "EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC",
  EMPLOYEE_TERMINATED_FOR_FNC_RESULT = "EMPLOYEE_TERMINATED_FOR_FNC_RESULT",
  INCORRECT_DATA = "INCORRECT_DATA",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS",
  EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA = "EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA",
  OTHER = "OTHER",
}

/**
 * All accepted reasons for closing a case when the worker chose to close the case
 * after receiving a TNC (Tentative Non-Confirmation)
 */
export enum EEVerifyCaseClosureReasonsAfterCloseOnTNC {
  INCORRECT_DATA = "INCORRECT_DATA",
  OTHER = "OTHER",
}

/**
 * The reason a duplicate case is being opened
 */
export enum EEVerifyDuplicateContinueReasonCodes {
  REHIRE = "REHIRE",
  INCORRECT_INFORMATION_ENTERED = "INCORRECT_INFORMATION_ENTERED",
  DHS_ASKED_ME_TO_CREATE_A_NEW_CASE = "DHS_ASKED_ME_TO_CREATE_A_NEW_CASE",
  SSA_ASKED_ME_TO_CREATE_A_NEW_CASE = "SSA_ASKED_ME_TO_CREATE_A_NEW_CASE",
  PREVIOUS_CASE_RESULT_RESUBMIT = "PREVIOUS_CASE_RESULT_RESUBMIT",
  OTHER = "OTHER",
}

/**
 * The reason a case is delayed
 */
export enum EEVerifyDelayedCaseReasonCodes {
  AWAITING_SOCIAL_SECURITY_NUMBER = "AWAITING_SOCIAL_SECURITY_NUMBER",
  TECHNICAL_PROBLEMS = "TECHNICAL_PROBLEMS",
  AUDIT_REVEALED_THAT_NEW_HIRE_WAS_NOT_RUN = "AUDIT_REVEALED_THAT_NEW_HIRE_WAS_NOT_RUN",
  FEDERAL_CONTRACTOR = "FEDERAL_CONTRACTOR",
  OTHER = "OTHER",
  AWAITING_RESPONSE_ON_CASE_WITH_INCORRECT_INFO = "AWAITING_RESPONSE_ON_CASE_WITH_INCORRECT_INFO",
}

/**
 * The result of matching photos
 */
export enum EEVerifyPhotoMatchResults {
  NO_IMAGE = "no-image",
  NOT_MATCHING = "not-matching",
  MATCHING = "matching",
}
