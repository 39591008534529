import { z } from "zod";
import { EKYCStatus } from "../kyc/EKYCStatus";
import { schemes } from "../schema-validation";
import {
  companyID,
  dob,
  email,
  formattedName,
  jurisdiction,
  ssn,
  zipCode,
} from "../schema-validation/schemes";
import { EEmploymentStatus } from "../workers-shared";
import { ELaForgeWorkerEnum } from "../workers-shared/ELaForgeWorkerEnum";

// (incomplete) but intended to mimic the shape of a contractor in la-forge db
export const contractorSchema = z.object({
  _id: schemes.mongoObjectId(),
  address: z.string().optional(),
  address_line2: z.string().optional(),
  business_name: z.string().optional(),
  city: z.string().optional(),
  companyID: companyID().optional(),
  complete_paperwork: z.boolean().default(false),
  dob: dob().optional(),
  ein: z.string().optional(),
  email: email(),
  employment_status: z
    .nativeEnum(EEmploymentStatus)
    .default(EEmploymentStatus.LIVE),
  first_name: formattedName().optional(),
  instant_pay_enabled: z.boolean().default(false),
  kyc_status: z.nativeEnum(EKYCStatus).optional(),
  last_name: formattedName().optional(),
  middle_name: z.string().optional(),
  onboard_docs_faxed: z.boolean().default(false),
  onboarded: z.boolean().default(false),
  organization: z.string().optional(),
  paycard_enabled: z.boolean().default(false),
  ssn: ssn().optional(),
  state: jurisdiction().optional(),
  type: z.string().optional(),
  user_type: z
    .nativeEnum(ELaForgeWorkerEnum)
    .default(ELaForgeWorkerEnum.Contractor),
  working_state: jurisdiction().optional(),
  zip: zipCode().optional(),
});
